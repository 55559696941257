<template>
    <div class="desktop-trip-preview-card" v-if="translates && translates[langUrl]">
        <div class="desktop-trip-preview-card__carousel">
            <div :style="`background-image: url(${ imageSrc(trip.pictures[trip.mainPictureIndex]) })` " class="desktop-trip-preview-card__img" @click="open( trip.code )"></div>
        </div>
        <div class="desktop-trip-preview-card__info-container">
            <div class="desktop-trip-preview-card__info">
                <div class="desktop-trip-preview-card__title-container">
                    <div class="desktop-trip-preview-card__label" :class="qualified <= qualifiedQuizCount? '' : 'open'" v-if="trip.level">
                        <img v-if="(qualified <= qualifiedQuizCount)" src="../../../assets/lock.svg" />
                        <img v-else src="../../../assets/lock-open.svg" />
                        <span>{{ TRIP_LEVEL_TITLE[trip.level] }}</span>
                    </div>
                    <h3 class="desktop-trip-preview-card__title">{{ trip.name }}</h3>
                </diV>
                <div v-if="!bloggersList" class="desktop-trip-preview-card__details-container">
                    <div class="desktop-trip-preview-card__details-container__details">
                        <template v-if="trip.type === 'host'">
                            <span class="desktop-trip-preview-card__details-container__price">
                                <span v-html="priceFormated(trip.price, trip.currency)"></span>
                            </span>
                        </template>
                        <template v-else-if="nearestArrival">
                            <span class="desktop-trip-preview-card__details-container__price">
                                <span v-html="priceFormated(nearestArrival.price, nearestArrival.currency)"></span>
                            </span>
                        </template>
                        <span>
                            / {{ trip.programs.length }} {{ lastDigitToWord(trip.programs.length) }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="desktop-trip-preview-card__description-and-buttons">
                <div class="desktop-trip-preview-card__description">
                    <div class="desktop-trip-preview-card__description__line">
                        <div class="desktop-trip-preview-card__description__name">
                            {{ translates[langUrl].startPlace[lang] }}
                        </div>
                        <div v-if="trip.direction[0]" class="desktop-trip-preview-card__description__value"> 
                            {{ trip.direction[0].country? trip.direction[0].country.title: '' }}{{ trip.direction[0].state? ', ' + trip.direction[0].state.title: '' }}{{ trip.direction[0].city? ', ' + trip.direction[0].city.title: '' }}
                        </div>
                    </div>
                    <div class="desktop-trip-preview-card__description__line">
                        <div class="desktop-trip-preview-card__description__name">
                            {{ translates[langUrl].finishPlace[lang] }}
                        </div>
                        <div v-if="trip.direction[0]" class="desktop-trip-preview-card__description__value"> 
                            {{ trip.direction[trip.direction.length - 1].country? trip.direction[trip.direction.length - 1].country.title: '' }}{{ trip.direction[trip.direction.length - 1].state? ', ' + trip.direction[trip.direction.length - 1].state.title: '' }}{{ trip.direction[trip.direction.length - 1].city? ', ' + trip.direction[trip.direction.length - 1].city.title: '' }}
                        </div>
                    </div>
                    <div class="desktop-trip-preview-card__description__line">
                        <div class="desktop-trip-preview-card__description__name">
                            {{ translates[langUrl].duration[lang] }}
                        </div>
                        <div class="desktop-trip-preview-card__description__value">
                            {{ trip.programs.length }} {{ lastDigitToWord(trip.programs.length) }}
                        </div>
                    </div>
                </div>
                <div class="desktop-trip-preview-card__buttons">
                    <!-- <div 
                        v-if="buttons.indexOf('bloggerRequest') >= 0"
                        class="button desktop-trip-preview-card__button desktop-trip-preview-card__button-fill" 
                        @click="tripRequest">{{ translates[langUrl].button_tripRequest[lang] }}</div> -->
                    <div 
                        class="button desktop-trip-preview-card__button desktop-trip-preview-card__button-fill" 
                        @click="open( trip.code )">{{ translates[langUrl].button_openTrip[lang] }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import moment from '@/plugins/moment'
    import { mapState } from 'vuex';
    import { TRIP_STATUS, TRIP_STATUS_TITLE, TRIP_LEVEL, TRIP_LEVEL_TITLE, TRIP_LEVEL_QUALIFIED_COUNT } from '@/vars';

    import { imageSrc, priceFormated, lastDigitToWord } from '@/helpers';
    
    export default {
        name: 'TripPreviewCard',
        components: {
        },
        props: {
            trip: {
                type: Object,
                default: () => {},
            },
            buttons: {
                type: Array,
                default: () => []
            },
            bloggersList: {
                type: Boolean,
                default: false
            },
            qualified : {
                type: Number,
                default: null
            }
        },
        data: () => ({
            imageSrc,
            priceFormated,
            lastDigitToWord,
            TRIP_STATUS,
            TRIP_STATUS_TITLE,
            TRIP_LEVEL_TITLE,
            popupDownload: false,
            langUrl: 'RoutePreviewCard'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('dictionaries', ['types', 'labels']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            nearestArrival() {
                if (this.trip.bloggerTrips && this.trip.bloggerTrips[0] && this.trip.bloggerTrips[0].start && this.trip.bloggerTrips[0].finish) {
                    return {
                        price: +this.trip.bloggerTrips[0].price + this.trip.bloggerTrips[0].bloggerAward,
                        currency: process.env.VUE_APP_DEFAULT_CURRENCY,
                        dates: `${ moment(this.trip.bloggerTrips[0].start).format('DD MMM') } - ${ moment(this.trip.bloggerTrips[0].finish).format('DD MMM') }`
                    };
                }
                if (this.trip.arrivals[0] && this.trip.arrivals[0].start && this.trip.arrivals[0].finish) {
                    return {
                        price: +this.trip.arrivals[0].price,
                        currency: this.trip.arrivals[0].currency,
                        dates: `${ moment(this.trip.arrivals[0].start).format('DD MMM') } - ${ moment(this.trip.arrivals[0].finish).format('DD MMM') }`
                    };
                } 
                return null;
            },
            formattedMainType() {
                if(this.trip.mainType) {
                    return this.types.filter(item => (item.code === this.trip.mainType))[0].title;
                } else {
                    return ''
                }
            },
            formattedAdditionalTypes() {
                if(this.trip.additionalTypes) {
                    return this.types.filter(item => (this.trip.additionalTypes.indexOf(item.code) >= 0)).map(item => item.title);
                } else {
                    return ''
                }
            },
            formattedLabels() {
                return this.labels.filter(item => (item.code === this.trip.label))[0].title;
            },
            status () {
                return TRIP_STATUS_TITLE[this.trip.status];
            },
            price () {
                let price = this.trip.arrivals[0] ? this.trip.arrivals[0].price : 0;
                return new Intl.NumberFormat('ru-RU').format(price);
            },
            mainPlacesArray () {
                return this.trip && this.trip.mainPlaces && this.trip.mainPlaces[0] ? this.trip.mainPlaces[0].split(',') : []
            },
            inFavorites() {
                if (this.user._id) {
                    return this.user.favoriteTrips.indexOf(this.trip._id) >= 0;
                }
                return false;
            },
            testLink() {
                return `${ process.env.VUE_APP_CURRENT_URL }/site/${ this.trip.code }`;
            },
            subdomain() {
                return `${ process.env.VUE_APP_PROTOCOL }${ this.trip.code }${ process.env.VUE_APP_SUBDOMAIN_POSTFIX }`;
            },
            qualifiedQuizCount() {
                switch(this.trip.level) {
                    case TRIP_LEVEL.EXPERT: 
                        return this.user.qualifiedQuizCountExpert || TRIP_LEVEL_QUALIFIED_COUNT.EXPERT;
                        break;
                    case TRIP_LEVEL.ADVANCED: 
                        return this.user.qualifiedQuizCountAdvanced || TRIP_LEVEL_QUALIFIED_COUNT.ADVANCED;
                        break;
                    default: 
                        return this.user.qualifiedQuizCount || TRIP_LEVEL_QUALIFIED_COUNT.BEGINNER;
                        break;
                }
            }
        },
        methods: {
            async open(code) {
                if(this.bloggersList) {
                    await this.$router.push({ name: 'trip-bloggers', params: { code } });    
                } else {
                    await this.$router.push({ name: 'blogger-route', params: { code } });
                }
            },
            async tripRequest() {
                await this.$router.push({ name: 'trips-request-trip', params: { trip: this.trip._id } });
            },
            authorization() {
                if(!this.user._id) {
                    this.$root.$emit('popupLoginShow');
                }
            },
        }
    }
</script>

<style lang="scss">
.desktop-trip-preview-card {
	display: flex;
	flex-direction: row;
	width: 100%;
    min-height: 280px;
	background: #FFFFFF;
	border-radius: 20px;
	margin-bottom: 20px;
	overflow: hidden;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.02), 0px 4px 16px rgba(44, 47, 56, 0.12);
    // @media all and (min-width: 1280px) {
    //     margin: auto;
    //     max-width: 1400px;
    // }
    &__carousel {
        position: relative;
        width: 335px;
        min-height: 100%;
    }

    &__img {
        height: 100%;
        width: 335px;
        background-size: cover;
        cursor: pointer;
    }

    &__info-container {
        flex-direction: column;
        width: 100%;
    }

    &__info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px 0;
        margin: 0 20px;
        border-bottom: 1px solid #F6F7F9;
    }

    &__label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 116px;
        background: #273155;
        padding: 5px 10px;
        margin-bottom: 12px;
        border-radius: 5px;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        img {
            height: 13px;
            width: 13px;
            margin-right: 4px;
        }
        &.open {
            background: green;
            color: #FFFFFF;
        }
    }

    &__title{
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.02em;
        // margin-bottom: 4px;
    }

    &__details-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 5px;
            @media all and (min-width: 768px) {
                flex-direction: column;
            }

        &__details {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: #484848;
        }

        &__price {
            font-weight: 600;
        }
    }
    &__description-and-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__description {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #44414D;
            padding: 24px 20px;
        &__line {
            margin-right: 10px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: 12px;
            }
        &__name {
            min-width: 140px;
            margin-right: 20px;
            @media all and (min-width: 1280px) {
                width: 140px;
            }
        }
        &__value span {
            white-space: nowrap;
            // display: flex;
            // justify-content: flex-start;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 35px 20px 20px 0;
    }

    &__button {
        width: 195px;
        height: 48px;
        border: 1px solid #DA0A63 !important;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 14px;
        line-height: 140%;
        padding: 10px;
        margin-bottom: 16px;
        letter-spacing: -0.02em;
        background: #FFFFFF;
        color: #DA0A63;
        cursor: pointer;
        &__icon {
            width: 15px;
            height: 15px;
            margin-left: 5px;
        }
    }

    &__button-fill {
        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
        color: #FFFFFF;
    }
}
</style>